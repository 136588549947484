import { UpArrow } from '@/assets/icons';
import React, { useEffect } from 'react';

const ScrollToTop = () => {
  const handleToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="container relative cursor-pointer " onClick={handleToTop}>
      <div className="bg-[#611C12] shadow rounded-full w-12 h-12 flex items-center justify-center">
        <UpArrow />
      </div>
    </div>
  );
};

export default ScrollToTop;
