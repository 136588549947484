import { TYPE_QUERY_KEY } from '@/constants/typeQueryKey';
import { useQuery } from 'react-query';
import { common } from '@/services/apis';
import { IMenu, IParams } from '@/interfaces';
import { useMemo } from 'react';

export const useGetMenus = (params?: any) => {
  const { data, isLoading } = useQuery([TYPE_QUERY_KEY.GET_MENU, params], () =>
    common.getMenus(params),
  );
  const formattedData = useMemo(() => {
    return data?.data?.map(item => {
      const { title, slug, page, menus } = item.attributes ?? {};
      return {
        title,
        slug,
        locale: item?.attributes?.locale,
        page: page?.data?.attributes?.name,
        menus: menus?.data?.map(menu => {
          const { title, slug } = menu.attributes ?? {};

          return { title, slug };
        }),
      } as unknown as IMenu;
    });
  }, [data]);

  return {
    data: formattedData,
    isLoading,
  };
};
