import { useGetSetting } from '@/hooks/apis/Setting/useGetSetting';
import { LazyImage } from '..';
import { useRouter } from 'next/router';
import useTrans from '@/hooks/useTrans';
import { useGetMenus } from '@/hooks/apis';
import Link from 'next/link';
import ScrollToTop from './ScrollToTop';
import { EmailFooter, PhoneFooter } from '@/assets/icons';

const Footer = () => {
  const router = useRouter();
  const locale = router.locale;
  const { data: settings } = useGetSetting(router.locale || '');
  const { data: menus } = useGetMenus({
    'filters[position]': 'footer',
    sort: 'order:desc',
    locale,
  });
  const trans = useTrans();

  return (
    <div className="w-full px-3 pt-16 text-white bg-footer lg:px-0" id="footer">
      <div className="container relative flex flex-col items-center">
        <div className="grid w-full lg:grid-cols-2">
          <div className="col-span-1 flex flex-col gap-3 lg:border-r-[1px] lg:border-white/50">
            {settings?.urlLogo && (
              <LazyImage
                alt="logo"
                src={settings?.urlLogo}
                width={182}
                height={53}
                className="object-contain"
              />
            )}
            <div className="lg:flex">
              <div className="flex flex-col gap-3 max-w-[340px] lg:pr-5">
                <p className="text-lg font-bold lg:text-sm">
                  {settings?.company_name}
                </p>
                <p className="text-lg lg:text-sm">{settings?.address}</p>
              </div>
              <div className="">
                <p className="pt-5 pb-6 text-lg font-medium lg:pt-0">
                  {trans.footer.contactUs}
                </p>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2">
                    <EmailFooter />
                    <p className="text-lg lg:text-sm">
                      {/* {trans.footer.email} */}
                      {/* <br /> */}
                      <a href={`mailto:${settings?.email}`}>
                        {settings?.email}
                      </a>
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <PhoneFooter />
                    <p className="text-lg lg:text-sm">
                      {/* {trans.footer.phone} */}
                      {/* <br /> */}
                      <a href={`tel:${settings?.phone}`}>{settings?.phone}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="pb-8 text-lg lg:pt-20 lg:pr-10 lg:text-sm">
              {settings?.info}
            </p>
          </div>
          <div className="col-span-1 flex flex-col gap-3">
            <div className="h-[53px] border-t-[1px] lg:border-none" />
            <div className="grid pt lg:grid-cols-2 lg:pl-10">
              {menus?.map((item, index) => {
                return (
                  <div key={index} className="col-span-1">
                    <div className="pb-6 text-lg font-medium">
                      {item.title}
                      <div className="flex flex-col gap-2 pt-6">
                        {item?.menus?.map((itemChild, index) => {
                          return (
                            <Link
                              key={index}
                              className="text-lg font-normal lg:text-sm"
                              href={`/${itemChild?.slug || ''}`}
                            >
                              {itemChild?.title}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="py-14">
          <p>{trans.footer.copyright}</p>
        </div>
        <div className="absolute -top-20 right-0">
          <ScrollToTop />
        </div>
      </div>
    </div>
  );
};

export default Footer;
